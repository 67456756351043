import * as THREE from "three";

import Loader from "./Utils/Loader.js";
import EventEmitter from "./Utils/EventEmitter.js";

// Matcaps
import matcapBeigeSource from "../models/matcaps/beige.png";
import matcapBlackSource from "../models/matcaps/black.png";
import matcapOrangeSource from "../models/matcaps/orange.png";
import matcapRedSource from "../models/matcaps/red.png";
import matcapWhiteSource from "../models/matcaps/white.png";
import matcapGreenSource from "../models/matcaps/green.png";
import matcapBrownSource from "../models/matcaps/brown.png";
import matcapGraySource from "../models/matcaps/gray.png";
import matcapEmeraldGreenSource from "../models/matcaps/emeraldGreen.png";
import matcapPurpleSource from "../models/matcaps/purple.png";
import matcapBlueSource from "../models/matcaps/blue.png";
import matcapYellowSource from "../models/matcaps/yellow.png";
import matcapMetalSource from "../models/matcaps/metal.png";

// Intro
import introStaticBaseSource from "../models/intro/static/base-2.glb";
import introStaticCollisionSource from "../models/intro/static/coll-2.glb";
import introStaticFloorShadowSource from "../models/intro/static/floorShadow.png";

import introInstructionsLabelsSource from "../models/intro/instructions/labels.glb";
import introInstructionsArrowsSource from "../models/intro/instructions/arrows.png";
import introInstructionsControlsSource from "../models/intro/instructions/controls.png";
import introInstructionsOtherSource from "../models/intro/instructions/other.png";
import additionalFloorText from "../models/intro/instructions/floorTexture.png";
import twitterSource from "../models/intro/instructions/floor-twitter.png";
import telegramSource from "../models/intro/instructions/floor-telegram.png";
import followusSource from "../models/intro/instructions/floor-followus.png";

import introArrowKeyBaseSource from "../models/intro/arrowKey/base.glb";
import introArrowKeyCollisionSource from "../models/intro/arrowKey/collision.glb";

// Car cyber truck
import carCyberTruckChassisSource from "../models/car/cyberTruck/chassis.glb";
import carCyberTruckWheelSource from "../models/car/cyberTruck/wheel.glb";
import carCyberTruckBackLightsBrakeSource from "../models/car/cyberTruck/backLightsBrake.glb";
import carCyberTruckBackLightsReverseSource from "../models/car/cyberTruck/backLightsReverse.glb";
import carCyberTruckAntenaSource from "../models/car/cyberTruck/antena.glb";

// Playground
import playgroundStaticFloorShadowSource from "../models/playground/static/floorShadow.png";
import playgroundStaticBaseSource from "../models/playground/static/base.glb";
import playgroundStaticCollisionSource from "../models/playground/static/collision.glb";

// Brick
import brickBaseSource from "../models/brick/base.glb";
import brickCollisionSource from "../models/brick/collision.glb";

// Horn
import hornBaseSource from "../models/horn/base.glb";
import hornCollisionSource from "../models/horn/collision.glb";

// Lemon
import lemonBaseSource from "../models/lemon/base.glb";
import lemonCollisionSource from "../models/lemon/collision.glb";

// Bowling ball
import bowlingBallBaseSource from "../models/bowlingBall/base.glb";
import bowlingBallCollisionSource from "../models/bowlingBall/collision.glb";

// Bowling pin
import bowlingPinBaseSource from "../models/bowlingPin/base.glb";
import bowlingPinCollisionSource from "../models/bowlingPin/collision.glb";

// Area
import areaKeyEnterSource from "../models/area/keyEnter.png";
import areaEnterSource from "../models/area/enter.png";
import areaOpenSource from "../models/area/open.png";
import areaResetSource from "../models/area/reset.png";
import areaQuestionMarkSource from "../models/area/questionMark.png";

// Tiles
import tilesABaseSource from "../models/tiles/a/base.glb";
import tilesACollisionSource from "../models/tiles/a/collision.glb";

import tilesBBaseSource from "../models/tiles/b/base.glb";
import tilesBCollisionSource from "../models/tiles/b/collision.glb";

import tilesCBaseSource from "../models/tiles/c/base.glb";
import tilesCCollisionSource from "../models/tiles/c/collision.glb";

import tilesDBaseSource from "../models/tiles/d/base.glb";
import tilesDCollisionSource from "../models/tiles/d/collision.glb";

import tilesEBaseSource from "../models/tiles/e/base.glb";
import tilesECollisionSource from "../models/tiles/e/collision.glb";

// Konami
import konamiLabelSource from "../models/konami/label.png";
import konamiLabelTouchSource from "../models/konami/label-touch.png";

export default class Resources extends EventEmitter {
    constructor() {
        super();

        this.loader = new Loader();
        this.items = {};

        this.loader.load([
            // Matcaps
            { name: "matcapBeige", source: matcapBeigeSource, type: "texture" },
            { name: "matcapBlack", source: matcapBlackSource, type: "texture" },
            {
                name: "matcapOrange",
                source: matcapOrangeSource,
                type: "texture",
            },
            { name: "matcapRed", source: matcapRedSource, type: "texture" },
            { name: "matcapWhite", source: matcapWhiteSource, type: "texture" },
            { name: "matcapGreen", source: matcapGreenSource, type: "texture" },
            { name: "matcapBrown", source: matcapBrownSource, type: "texture" },
            { name: "matcapGray", source: matcapGraySource, type: "texture" },
            {
                name: "matcapEmeraldGreen",
                source: matcapEmeraldGreenSource,
                type: "texture",
            },
            {
                name: "matcapPurple",
                source: matcapPurpleSource,
                type: "texture",
            },
            { name: "matcapBlue", source: matcapBlueSource, type: "texture" },
            {
                name: "matcapYellow",
                source: matcapYellowSource,
                type: "texture",
            },
            { name: "matcapMetal", source: matcapMetalSource, type: "texture" },
            // { name: 'matcapGold', source: matcapGoldSource, type: 'texture' },

            // Intro
            { name: "introStaticBase", source: introStaticBaseSource },
            {
                name: "introStaticCollision",
                source: introStaticCollisionSource,
            },
            {
                name: "introStaticFloorShadow",
                source: introStaticFloorShadowSource,
                type: "texture",
            },

            {
                name: "introInstructionsLabels",
                source: introInstructionsLabelsSource,
            },
            {
                name: "introInstructionsArrows",
                source: introInstructionsArrowsSource,
                type: "texture",
            },
            {
                name: "introInstructionsControls",
                source: introInstructionsControlsSource,
                type: "texture",
            },
            {
                name: "introInstructionsOther",
                source: introInstructionsOtherSource,
                type: "texture",
            },
            {
                name: "additionalFloorText",
                source: additionalFloorText,
                type: "texture",
            },

            {
                name: "twitterSource",
                source: twitterSource,
                type: "texture",
            },
            {
                name: "telegramSource",
                source: telegramSource,
                type: "texture",
            },
            {
                name: "followusSource",
                source: followusSource,
                type: "texture",
            },

            { name: "introArrowKeyBase", source: introArrowKeyBaseSource },
            {
                name: "introArrowKeyCollision",
                source: introArrowKeyCollisionSource,
            },

            // Car default
            {
                name: "carCyberTruckChassis",
                source: carCyberTruckChassisSource,
            },
            { name: "carCyberTruckWheel", source: carCyberTruckWheelSource },
            {
                name: "carCyberTruckBackLightsBrake",
                source: carCyberTruckBackLightsBrakeSource,
            },
            {
                name: "carCyberTruckBackLightsReverse",
                source: carCyberTruckBackLightsReverseSource,
            },
            { name: "carCyberTruckAntena", source: carCyberTruckAntenaSource },

            // Playground
            {
                name: "playgroundStaticBase",
                source: playgroundStaticBaseSource,
            },
            {
                name: "playgroundStaticCollision",
                source: playgroundStaticCollisionSource,
            },
            {
                name: "playgroundStaticFloorShadow",
                source: playgroundStaticFloorShadowSource,
                type: "texture",
            },

            // Brick
            { name: "brickBase", source: brickBaseSource },
            { name: "brickCollision", source: brickCollisionSource },

            // Horn
            { name: "hornBase", source: hornBaseSource },
            { name: "hornCollision", source: hornCollisionSource },

            // Webby trophy

            // Lemon
            { name: "lemonBase", source: lemonBaseSource },
            { name: "lemonCollision", source: lemonCollisionSource },

            // Bownling ball
            { name: "bowlingBallBase", source: bowlingBallBaseSource },
            {
                name: "bowlingBallCollision",
                source: bowlingBallCollisionSource,
            },

            // Bownling ball
            { name: "bowlingPinBase", source: bowlingPinBaseSource },
            { name: "bowlingPinCollision", source: bowlingPinCollisionSource },

            // Areas
            {
                name: "areaKeyEnter",
                source: areaKeyEnterSource,
                type: "texture",
            },
            { name: "areaEnter", source: areaEnterSource, type: "texture" },
            { name: "areaOpen", source: areaOpenSource, type: "texture" },
            { name: "areaReset", source: areaResetSource, type: "texture" },
            {
                name: "areaQuestionMark",
                source: areaQuestionMarkSource,
                type: "texture",
            },

            // Tiles
            { name: "tilesABase", source: tilesABaseSource },
            { name: "tilesACollision", source: tilesACollisionSource },

            { name: "tilesBBase", source: tilesBBaseSource },
            { name: "tilesBCollision", source: tilesBCollisionSource },

            { name: "tilesCBase", source: tilesCBaseSource },
            { name: "tilesCCollision", source: tilesCCollisionSource },

            { name: "tilesDBase", source: tilesDBaseSource },
            { name: "tilesDCollision", source: tilesDCollisionSource },

            { name: "tilesEBase", source: tilesEBaseSource },
            { name: "tilesECollision", source: tilesECollisionSource },

            // Konami
            { name: "konamiLabel", source: konamiLabelSource, type: "texture" },
            {
                name: "konamiLabelTouch",
                source: konamiLabelTouchSource,
                type: "texture",
            },
        ]);

        this.loader.on("fileEnd", (_resource, _data) => {
            this.items[_resource.name] = _data;

            // Texture
            if (_resource.type === "texture") {
                const texture = new THREE.Texture(_data);
                texture.needsUpdate = true;

                this.items[`${_resource.name}Texture`] = texture;
            }

            // Trigger progress
            this.trigger("progress", [this.loader.loaded / this.loader.toLoad]);
        });

        this.loader.on("end", () => {
            // Trigger ready
            this.trigger("ready");
        });
    }
}
