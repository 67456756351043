import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import * as THREE from "three";

import showcaseModel from "../../models/showcase/base-opt.glb";

const soicalContainer = document.querySelector(".social-container");

const subtitle = document.querySelector(".subtitle");

const toolTip = document.querySelector(".tooltip");

export class Showcase {
    constructor(_options) {
        // Draco loader
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath(
            "https://www.gstatic.com/draco/v1/decoders/"
        );

        // GLTF loader
        this.gltfLoader = new GLTFLoader();
        this.gltfLoader.setDRACOLoader(this.dracoLoader);

        this.scene = _options.scene;
        this.camera = _options.camera;

        this.showcase;
        this.init();
    }

    init() {
        const light = new THREE.DirectionalLight("#ffffff", 5 * Math.PI);
        const ambientLight = new THREE.AmbientLight("#ffffff", 0.8);
        // 1, 1, 1 goof position
        // light2.position.set(0.5, 0, 0.866);
        light.position.set(15.5, -15, 3.6);
        ambientLight.position.set(5, 1, 5);
        this.gltfLoader.load(showcaseModel, (gltf) => {
            this.showcase = gltf.scene;
            this.car;

            this.showcase.traverse((node) => {
                if (node.isMesh) {
                    node.castShadow = true;
                    node.receiveShadow = true;
                }
                if (node.isLight) {
                    node.visible = { punctualLightsEnabled: true };
                }
            });
            this.showcase.position.set(
                0.240230962499906,
                0.359766427863317,
                -0.188780270374355
            );
            this.showcase.rotation.set(Math.PI / 2, Math.PI / 4, 0);
            this.showcase.name = "showcase";
            this.scene.add(this.showcase);
        });

        this.scene.add(light);
        this.scene.add(ambientLight);
    }

    destructor() {
        this.scene.remove(this.showcase);
        soicalContainer.style.display = "none";
        subtitle.style.display = "none";
        toolTip.style.display = "none";
    }
}
